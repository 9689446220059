import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  // public
  {
    path: "/",
    component: () => import("@/layouts/default"),
    children: [
      {
        path: "",
        name: "Home",
        redirect: () => {
          return { path: "/login" };
        },
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "register",
        name: "Register",
        component: () => import("@/views/auth/Register.vue"),
      },
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: () => import("@/views/auth/ForgotPassword.vue"),
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: () => import("@/views/auth/ResetPassword.vue"),
      },
      {
        path: "request-verify-email",
        name: "RequestVerifyEmail",
        component: () => import("@/views/auth/RequestVerifyEmail.vue"),
      },
      {
        path: "verify-email",
        name: "VerifyEmail",
        component: () => import("@/views/auth/VerifyEmail.vue"),
      },
      {
        path: "company-accept-invite",
        name: "CompanyAcceptInvite",
        component: () => import("@/views/invites/CompanyAcceptInvite.vue"),
      },
      {
        path: "user-accept-invite",
        name: "UserAcceptInvite",
        component: () => import("@/views/invites/UserAcceptInvite.vue"),
      },
    ],
  },
  // dashboard
  {
    path: "/",
    component: () => import("@/layouts/dashboard"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard/home",
        name: "DashboardHome",
        component: () => import("@/views/dashboard/Dashboard.vue"),
      },
      {
        path: "dashboard/inbox",
        name: "DashboardInbox",
        component: () => import("@/views/dashboard/Inbox.vue"),
      },
      {
        path: "dashboard/inbox/:id",
        name: "DashboardInboxDetails",
        component: () => import("@/views/dashboard/InboxDetails.vue"),
      },
      {
        path: "dashboard/addresses",
        name: "DashboardAddresses",
        component: () => import("@/views/dashboard/Addresses.vue"),
      },
      {
        path: "dashboard/ai-models",
        name: "DashboardAiModels",
        component: () => import("@/views/dashboard/AiModels.vue"),
      },
      {
        path: "dashboard/ai-models/:id",
        name: "DashboardAiModelView",
        component: () => import("@/views/dashboard/AiModelView.vue"),
      },
      {
        path: "dashboard/api-keys",
        name: "DashboardApiKeys",
        component: () => import("@/views/dashboard/ApiKeys.vue"),
      },
      {
        path: "dashboard/categories",
        name: "DashboardCategories",
        component: () => import("@/views/dashboard/Categories.vue"),
      },
      {
        path: "dashboard/category-columns",
        name: "DashboardCategoryColumns",
        component: () => import("@/views/dashboard/CategoryColumns.vue"),
      },
      {
        path: "dashboard/companies",
        name: "DashboardCompanies",
        component: () => import("@/views/dashboard/Companies.vue"),
      },
      {
        path: "dashboard/contacts",
        name: "DashboardContacts",
        component: () => import("@/views/dashboard/Contacts.vue"),
      },
      {
        path: "dashboard/credits",
        name: "DashboardCredits",
        component: () => import("@/views/dashboard/Credits.vue"),
      },
      {
        path: "dashboard/credit-transactions",
        name: "DashboardCreditTransactions",
        component: () => import("@/views/dashboard/CreditTransactions.vue"),
      },
      {
        path: "dashboard/documents",
        name: "DashboardDocuments",
        component: () => import("@/views/dashboard/Documents.vue"),
      },
      {
        path: "dashboard/document-lines",
        name: "DashboardDocumentLines",
        component: () => import("@/views/dashboard/DocumentLines.vue"),
      },
      {
        path: "dashboard/images",
        name: "DashboardImages",
        component: () => import("@/views/dashboard/Images.vue"),
      },
      {
        path: "dashboard/invites",
        name: "DashboardInvites",
        component: () => import("@/views/dashboard/Invites.vue"),
      },
      {
        path: "dashboard/password-resets",
        name: "DashboardPasswordResets",
        component: () => import("@/views/dashboard/PasswordResets.vue"),
      },
      {
        path: "dashboard/permissions",
        name: "DashboardPermissions",
        component: () => import("@/views/dashboard/Permissions.vue"),
      },
      {
        path: "dashboard/roles",
        name: "DashboardRoles",
        component: () => import("@/views/dashboard/Roles.vue"),
      },
      {
        path: "dashboard/service-categories",
        name: "DashboardServiceCategories",
        component: () => import("@/views/dashboard/ServiceCategories.vue"),
      },
      {
        path: "dashboard/service-details",
        name: "DashboardServiceDetails",
        component: () => import("@/views/dashboard/ServiceDetails.vue"),
      },
      {
        path: "dashboard/services",
        name: "DashboardServices",
        component: () => import("@/views/dashboard/Services.vue"),
      },
      {
        path: "dashboard/users",
        name: "DashboardUsers",
        component: () => import("@/views/dashboard/Users.vue"),
      },
      // {
      //   path: "dashboard/vendors",
      //   name: "DashboardVendors",
      //   component: () => import("@/views/dashboard/Vendors.vue"),
      // },
      {
        path: "dashboard/profile",
        name: "DashboardProfile",
        component: () => import("@/views/dashboard/Profile.vue"),
      },
      {
        path: "payment-canceled",
        name: "CanceledPayment",
        component: () =>
          import("@/views/dashboard/payment/CanceledPayment.vue"),
      },
      {
        path: "payment-error",
        name: "ErrorPayment",
        component: () => import("@/views/dashboard/payment/ErrorPayment.vue"),
      },
      {
        path: "payment-notify",
        name: "NotifyPayment",
        component: () => import("@/views/dashboard/payment/NotifyPayment.vue"),
      },
      {
        path: "payment-successful",
        name: "SuccessfulPayment",
        component: () =>
          import("@/views/dashboard/payment/SuccessfulPayment.vue"),
      },
    ],
  },
  // {
  //   path: "/dashboard/inbox",
  //   name: "DashboardInbox",
  //   component: () => import("../views/dashboard/Inbox.vue"),
  // },
  // {
  //   path: "/dashboard/inbox/:id",
  //   name: "DashboardInboxDetails",
  //   component: () => import("../views/dashboard/InboxDetails.vue"),
  // },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },

  {
    path: "/:catchAll(.*)",
    redirect: { name: "Login" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.auth.loggedIn) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
