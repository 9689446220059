const permission = {
  namespaced: true,

  state: {
    permissions: [],
  },

  mutations: {
    setPermissions(state, payload) {
      state.permissions = payload;
    },
  },

  actions: {},

  getters: {
    getPermissions(state) {
      return state.permissions;
    },
  },
};

export default permission;
