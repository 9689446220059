import axios from "../../plugins/axios";

const apiEndpoints = {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    // apiEndpoint
    async apiEndpoint(reqData = { endpoint: "", bodyData: {} }) {
      var response = {
        status: 500,
        data: {
          message: "Internal Server Error!!",
        },
      };

      var endpoint = reqData.endpoint;
      var bodyData = reqData.bodyData;

      try {
        const res = await axios.post(endpoint, bodyData);

        response.status = res.status;
        response.data = res.data;
      } catch (error) {
        response.status = error.response.status;
        response.data = error.response.data;
      }

      return response;
    },
  },

  getters: {},
};

export default apiEndpoints;
