import axios from "./axios";
import Swal from "sweetalert2";
// import router from "./../router/index";

import store from "./../store/index";

import moment from "moment-timezone";

// datetime
function datetime(datetime) {
  // 2022-02-25T07:52:05.825863
  if (!datetime || datetime === "0001-01-01T00:00:00") {
    return "";
  }

  let now = moment().tz("Africa/Johannesburg");

  let dateTime = moment(datetime).add(2, "hours").tz("Africa/Johannesburg");

  const isAfter = dateTime.isAfter(now);

  if (isAfter) {
    return moment(datetime)
      .tz("Africa/Johannesburg")
      .format("DD-MMM-YYYY HH:mm");
  }

  return dateTime.format("DD-MMM-YYYY HH:mm");
}

// date
function date(datetime) {
  // 2022-02-25T07:52:05.825863
  if (!datetime || datetime === "0001-01-01T00:00:00") {
    return "";
  }

  return moment(datetime).tz("Africa/Johannesburg").format("DD-MMM-YYYY");
}

// time
function time(datetime) {
  // 2022-02-25T07:52:05.825863
  if (!datetime || datetime === "0001-01-01T00:00:00") {
    return "";
  }

  let now = moment().tz("Africa/Johannesburg");

  let dateTime = moment(datetime).add(2, "hours").tz("Africa/Johannesburg");

  const isAfter = dateTime.isAfter(now);

  if (isAfter) {
    return moment(datetime).tz("Africa/Johannesburg").format("HH:mm");
  }

  return dateTime.format("HH:mm");
}

// setBase64
function setBase64(base64, name) {
  var extention = name.split(".").pop();

  return "data:image/" + extention + ";base64," + base64;
}

// getApiData
/**
 * @param {string} endpoint - The string
 */
async function getApiData(endpoint) {
  var response = {
    status: 500,
    data: {
      message: "Internal Server Error!!",
    },
  };

  try {
    const res = await axios.get(endpoint);

    response.status = res.status;
    response.data = res.data;
  } catch (error) {
    response.status = error.response.status;
    response.data = error.response.data;

    handleError(response.status, response.data);
  }

  return response;
}

// createApiData
/**
 * @param {string} endpoint - The string
 * @param {Object} bodyData - The object
 * @param {string} message - The string
 */
async function createApiData(
  endpoint,
  bodyData,
  message = "Created Successfully!"
) {
  var response = {
    status: 500,
    data: {
      message: "Internal Server Error!!",
    },
  };

  try {
    const res = await axios.post(endpoint, bodyData);

    response.status = res.status;
    response.data = res.data;

    handleSuccess(response.status, { message: message });
  } catch (error) {
    response.status = error.response.status;
    response.data = error.response.data;

    handleError(response.status, response.data);
  }

  return response;
}

// createApiFormData
/**
 * @param {string} endpoint - The string
 * @param {string} message - The string
 */
async function createApiFormData(
  endpoint,
  bodyFormData,
  message = "Created Successfully!"
) {
  var response = {
    status: 500,
    data: {
      message: "Internal Server Error!!",
    },
  };

  try {
    const res = await axios.post(endpoint, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    response.status = res.status;
    response.data = res.data;

    handleSuccess(response.status, { message: message });
  } catch (error) {
    response.status = error.response.status;
    response.data = error.response.data;

    handleError(response.status, response.data);
  }

  return response;
}

// updateApiData
/**
 * @param {string} endpoint - The string
 * @param {Object} bodyData - The object
 * @param {string} message - The string
 */
async function updateApiData(
  endpoint,
  bodyData,
  message = "Updated Successfully!"
) {
  var response = {
    status: 500,
    data: {
      message: "Internal Server Error!!",
    },
  };

  try {
    const res = await axios.put(endpoint, bodyData);

    response.status = res.status;
    response.data = res.data;

    handleSuccess(response.status, { message: message });
  } catch (error) {
    response.status = error.response.status;
    response.data = error.response.data;

    handleError(response.status, response.data);
  }

  return response;
}

// deleteApiData
/**
 * @param {string} endpoint - The string
 * @param {string} message - The string
 */
async function deleteApiData(endpoint, message = "Deleted Successfully!") {
  var response = {
    status: 500,
    data: {
      message: "Internal Server Error!!",
    },
  };

  try {
    const res = await axios.delete(endpoint);

    response.status = res.status;
    response.data = res.data;

    handleSuccess(response.status, { message: message });
  } catch (error) {
    response.status = error.response.status;
    response.data = error.response.data;

    handleError(response.status, response.data);
  }

  return response;
}

// handleError
/**
 * @param {number} status - The number
 * @param {Object} data - The object
 */
function handleError(
  status = 500,
  data = {
    message: "Something went wrong please try again!",
  }
) {
  // console.log("handleError status", status);
  // console.log("handleError message", data.message);

  if (status == 401) {
    // console.log("401 message");
    store.dispatch("auth/logOutUser");
  } else if (status == 400) {
    // errors
    if ("errors" in data) {
      var errors = data.errors;

      if ("active" in errors) {
        swalError(errors.active[0]);
      } else if ("available" in errors) {
        swalError(errors.available[0]);
      } else if ("balance" in errors) {
        swalError(errors.balance[0]);
      } else if ("category_column_id" in errors) {
        swalError(errors.category_column_id[0]);
      } else if ("category_columns" in errors) {
        swalError(errors.category_columns[0]);
      } else if ("category_id" in errors) {
        swalError(errors.category_id[0]);
      } else if ("city" in errors) {
        swalError(errors.city[0]);
      } else if ("company_email" in errors) {
        swalError(errors.company_email[0]);
      } else if ("company_id" in errors) {
        swalError(errors.company_id[0]);
      } else if ("company_name" in errors) {
        swalError(errors.company_name[0]);
      } else if ("company_phone1" in errors) {
        swalError(errors.company_phone1[0]);
      } else if ("company_phone2" in errors) {
        swalError(errors.company_phone2[0]);
      } else if ("company_website" in errors) {
        swalError(errors.company_website[0]);
      } else if ("confirm_password" in errors) {
        swalError(errors.confirm_password[0]);
      } else if ("country" in errors) {
        swalError(errors.country[0]);
      } else if ("cost_number_of_credits" in errors) {
        swalError(errors.cost_number_of_credits[0]);
      } else if ("credit" in errors) {
        swalError(errors.credit[0]);
      } else if ("credit_id" in errors) {
        swalError(errors.credit_id[0]);
      } else if ("credit_transaction_id" in errors) {
        swalError(errors.credit_transaction_id[0]);
      } else if ("data" in errors) {
        swalError(errors.data[0]);
      } else if ("debit" in errors) {
        swalError(errors.debit[0]);
      } else if ("description" in errors) {
        swalError(errors.description[0]);
      } else if ("document_id" in errors) {
        swalError(errors.document_id[0]);
      } else if ("document_line_id" in errors) {
        swalError(errors.document_line_id[0]);
      } else if ("document_line_options" in errors) {
        swalError(errors.document_line_options[0]);
      } else if ("email" in errors) {
        swalError(errors.email[0]);
      } else if ("first_name" in errors) {
        swalError(errors.first_name[0]);
      } else if ("height" in errors) {
        swalError(errors.height[0]);
      } else if ("image_height" in errors) {
        swalError(errors.image_height[0]);
      } else if ("image_id" in errors) {
        swalError(errors.image_id[0]);
      } else if ("image_width" in errors) {
        swalError(errors.image_width[0]);
      } else if ("is_super_admin" in errors) {
        swalError(errors.is_super_admin[0]);
      } else if ("key_value_pairs" in errors) {
        swalError(errors.key_value_pairs[0]);
      } else if ("label_name" in errors) {
        swalError(errors.label_name[0]);
      } else if ("label_type" in errors) {
        swalError(errors.label_type[0]);
      } else if ("last_name" in errors) {
          swalError(errors.last_name[0]);
      } else if ("left" in errors) {
        swalError(errors.left[0]);
      } else if ("line_1" in errors) {
        swalError(errors.line_1[0]);
      } else if ("line_2" in errors) {
        swalError(errors.line_2[0]);
      } else if ("name" in errors) {
        swalError(errors.name[0]);
      } else if ("numberOfCredits" in errors) {
        swalError(errors.numberOfCredits[0]);
      } else if ("number_of_credits" in errors) {
        swalError(errors.number_of_credits[0]);
      } else if ("ozow_link" in errors) {
        swalError(errors.ozow_link[0]);
      } else if ("ozow_payment_request_id" in errors) {
        swalError(errors.ozow_payment_request_id[0]);
      } else if ("password" in errors) {
        swalError(errors.password[0]);
      } else if ("permissions" in errors) {
        swalError(errors.permissions[0]);
      } else if ("phone1" in errors) {
        swalError(errors.phone1[0]);
      } else if ("phone2" in errors) {
        swalError(errors.phone2[0]);
      } else if ("postal_code" in errors) {
        swalError(errors.postal_code[0]);
      } else if ("price" in errors) {
        swalError(errors.price[0]);
      } else if ("province" in errors) {
        swalError(errors.province[0]);
      } else if ("qty" in errors) {
        swalError(errors.qty[0]);
      } else if ("reference" in errors) {
        swalError(errors.reference[0]);
      } else if ("roles" in errors) {
        swalError(errors.roles[0]);
      } else if ("service_category_id" in errors) {
        swalError(errors.service_category_id[0]);
      } else if ("service_d" in errors) {
        swalError(errors.service_id[0]);
      } else if ("status" in errors) {
        swalError(errors.status[0]);
      } else if ("tax" in errors) {
        swalError(errors.tax[0]);
      } else if ("token" in errors) {
        swalError(errors.token[0]);
      } else if ("top" in errors) {
        swalError(errors.top[0]);
      } else if ("total" in errors) {
        swalError(errors.total[0]);
      } else if ("total_before_tax" in errors) {
        swalError(errors.total_before_tax[0]);
      } else if ("total_currency" in errors) {
        swalError(errors.total_currency[0]);
      } else if ("value" in errors) {
        swalError(errors.value[0]);
      } else if ("vendor_name" in errors) {
        swalError(errors.vendor_name[0]);
      } else if ("website" in errors) {
        swalError(errors.website[0]);
      } else if ("width" in errors) {
        swalError(errors.width[0]);
      } else {
        swalError("Something Went Wrong Please Try Again");
      }    
    }
  } else if (status == 422) {
    if ("message" in data) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.message,
      });
    }
  } else if (status == 403) {
    Swal.fire({
      toast: true,
      position: "top-end",
      icon: "success",
      title: data.message,
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  } else if (status == 404) {
    Swal.fire({
      title: "Not Found!",
      text: data.message,
      icon: "error",
      // confirmButtonText: "Ok",
    });
  } else if (status == 500) {
    Swal.fire({
      title: "Internal Server Error!",
      text: "Please try again!",
      icon: "error",
      // confirmButtonText: "Ok",
    });
  } else if (status == 503) {
    Swal.fire({
      title: "Service Temporarily Unavailable!!",
      text: "Please try again!",
      icon: "error",
      // confirmButtonText: "Ok",
    });
  } else {
    Swal.fire({
      title: "Internal Server Error!",
      text: "Please try again!!!",
      icon: "error",
      // confirmButtonText: "Ok",
    });
  }
}

// handleSuccess
/**
 * @param {number} status - The number
 * @param {Object} data - The object
 */
function handleSuccess(
  status = 200,
  data = {
    message: "Action Successfully!",
  }
) {
  // console.log("handleSuccess status", status);
  // console.log("handleSuccess message", data.message);

  if (status == 200) {
    Swal.fire({
      toast: true,
      position: "top-end",
      icon: "success",
      title: data.message,
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  } else if (status == 503) {
    Swal.fire({
      title: "Service Temporarily Unavailable!",
      text: "Please try again!",
      icon: "error",
      // confirmButtonText: "Ok",
    });
  } else {
    Swal.fire({
      title: "Internal Server Error!",
      text: "Please try again!",
      icon: "error",
      // confirmButtonText: "Ok",
    });
  }
}

// getIdByNameFromList
/**
 * @param {Array} data - The array
 * @param {string} name - The string
 */
function getIdByNameFromList(data, name) {
  var id = 0;

  var result = data.filter((obj) => {
    return obj.name === name;
  });

  if (result.length > 0) {
    id = result[0].id;
  }

  return id;
}

// getNameByIdFromList
/**
 * @param {Array} data - The array
 * @param {number} id - The number
 */
function getNameByIdFromList(data, id) {
  var name = "";

  var result = data.filter((obj) => {
    return obj.id === id;
  });

  if (result.length > 0) {
    name = result[0].name;
  }

  return name;
}

// getNamesFromList
/**
 * @param {Array} data - The array
 */
function getNamesFromList(data) {
  var names = [];

  for (var item of data) {
    names.push(item.name);
  }

  return names.sort();
}

// getIdsByNamesFromList
/**
 * @param {Array} data - The array
 * @param {Array} names - The array
 */
function getIdsByNamesFromList(data, names) {
  var ids = [];

  if (names == null) return ids;

  for (var name of names) {
    var result = data.filter((obj) => {
      return obj.name === name;
    });

    if (result.length > 0) {
      ids.push(result[0].id);
    }
  }

  // console.log("ids", ids);

  return ids.sort();
}

// getNameByIdFromList
/**
 * @param {Array} data - The array
 * @param {Array} ids - The array
 */
function getNamesByIdsFromList(data, ids) {
  var names = [];

  if (ids == null) return names;

  for (var id of ids) {
    var result = data.filter((obj) => {
      return obj.id === id;
    });

    if (result.length) {
      names.push(result[0].name);
    }
  }

  // console.log("names", names);

  return names.sort();
}

// formatInputMobileNumber
function formatInputMobileNumber(entry = "") {
  const match = entry
    .replace(/\D+/g, "")
    .replace(/^1/, "")
    .match(/([^\d]*\d[^\d]*){1,10}$/)[0];

  const part1 = match.length > 2 ? `${match.substring(0, 3)}` : match;
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : "";
  const part3 = match.length > 6 ? ` ${match.substring(6, 10)}` : "";
  // console.log("mobileNumber", `${part1}${part2}${part3}`);

  return `${part1}${part2}${part3}`;
}

// swalError
function swalError(message) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: message,
  });
}

// toTitleCase
function toTitleCase(str) {
  str = str.replaceAll("_", " ");

  var newStr = str
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(" ");

  return newStr;
}

// toSnakeCase
function toSnakeCase(str) {
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join("_");
}

// camelToTitle
function camelToTitle(str) {
  return str
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}

export default {
  datetime,
  date,
  time,
  setBase64,
  getApiData,
  createApiData,
  createApiFormData,
  updateApiData,
  deleteApiData,
  handleError,
  handleSuccess,
  getIdByNameFromList,
  getNameByIdFromList,
  getNamesFromList,
  getIdsByNamesFromList,
  getNamesByIdsFromList,
  formatInputMobileNumber,
  swalError,
  toTitleCase,
  toSnakeCase,
  camelToTitle,
};
