import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import store from "./store";

import instance from "./plugins/axios";
import helpers from "./plugins/helpers";
import VueAxios from "vue-axios";

import ability from "./plugins/ability";
import { abilitiesPlugin } from "@casl/vue";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import ProductZoomer from "vue-product-zoomer";
import JsonViewer from "vue-json-viewer";

import moment from "moment-timezone";

moment.tz.setDefault("Africa/Johannesburg");

Vue.prototype.moment = moment;

import VueCountdown from "@chenfengyuan/vue-countdown";

Vue.component(VueCountdown.name, VueCountdown);

// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer);

const options = {
  confirmButtonColor: "#3391ff",
  cancelButtonColor: "#ff7674",
};

Vue.config.productionTip = false;

const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};

Vue.use(plugin);

Vue.use(ProductZoomer);
Vue.use(VueAxios, instance);
Vue.use(VueSweetalert2, options);
Vue.use(abilitiesPlugin, ability);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
