import { defineAbility } from "@casl/ability";

export default defineAbility((can) => {
  // access
  can("access");

  /*

  // access
  can("access");

  // category
  can("category_access");
  can("category_show");
  can("category_create");
  can("category_update");
  can("category_delete");

  // company
  can("company_access");
  can("company_show");
  can("company_create");
  can("company_update");
  can("company_delete");

  // document_line
  can("document_line_access");
  can("document_line_show");
  can("document_line_create");
  can("document_line_update");
  can("document_line_delete");

  // document
  can("document_access");
  can("document_show");
  can("document_create");
  can("document_update");
  can("document_delete");

  // image
  can("image_access");
  can("image_show");
  can("image_create");
  can("image_update");
  can("image_delete");

  // invite
  can("invite_access");
  can("invite_show");
  can("invite_create");
  can("invite_update");
  can("invite_delete");
  can("invite_company");
  can("invite_company_accept");
  can("invite_user");
  can("invite_user_accept");

  // password_reset_access
  can("password_reset_access");
  can("password_reset_show");
  can("password_reset_create");
  can("password_reset_update");
  can("password_reset_delete");

  // permission
  can("permission_access");
  can("permission_show");
  can("permission_create");
  can("permission_update");
  can("permission_delete");

  // role
  can("role_access");
  can("role_show");
  can("role_create");
  can("role_update");
  can("role_delete");

  // user
  can("user_access");
  can("user_show");
  can("user_create");
  can("user_update");
  can("user_delete");

  */
});
//   "category_access",
//   "category_show",
//   "category_create",
//   "category_update",
//   "category_delete",
//   "company_access",
//   "company_show",
//   "company_create",
//   "company_update",
//   "company_delete",
//   "document_line_access",
//   "document_line_show",
//   "document_line_create",
//   "document_line_update",
//   "document_line_delete",
//   "document_access",
//   "document_show",
//   "document_create",
//   "document_update",
//   "document_delete",
//   "image_access",
//   "image_show",
//   "image_create",
//   "image_update",
//   "image_delete",
//   "invite_access",
//   "invite_show",
//   "invite_create",
//   "invite_update",
//   "invite_delete",
//   "invite_company",
//   "invite_company_accept",
//   "invite_user",
//   "invite_user_accept",
//   "password_reset_access",
//   "password_reset_show",
//   "password_reset_create",
//   "password_reset_update",
//   "password_reset_delete",
//   "permission_access",
//   "permission_show",
//   "permission_create",
//   "permission_update",
//   "permission_delete",
//   "role_access",
//   "role_show",
//   "role_create",
//   "role_update",
//   "role_delete",
//   "user_access",
//   "user_show",
//   "user_create",
//   "user_update",
//   "user_delete";
