<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { AbilityBuilder, Ability } from "@casl/ability";
// import { ABILITY_TOKEN } from "@casl/vue";

export default {
  name: "App",

  inject: {
    // $ability: { from: ABILITY_TOKEN },
  },

  data: () => ({
    //
  }),

  created() {
    let userPermissions = localStorage.getItem("userPermissions");

    if (userPermissions != null) {
      let permissions = localStorage.getItem("userPermissions").split(",");

      const { can, rules } = new AbilityBuilder(Ability);

      can(permissions);

      this.$ability.update(rules);
    }
  },
};
</script>
