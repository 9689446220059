const aiModel = {
  namespaced: true,

  state: {
    addAiModelDialog: false,
    dialogAiModel: false,
    totalTime: 15 * 60 * 1000,
    aiModelId: 0,
    timeLeft: 0,
    time: 0,
    countDown: 0,
  },

  mutations: {
    // setAddAiModelDialog
    setAddAiModelDialog(state, payload) {
      state.addAiModelDialog = payload;
    },

    // setDialogAiModel
    setDialogAiModel(state, payload) {
      state.dialogAiModel = payload;
    },

    // setTotalTime
    setTotalTime(state, payload) {
      state.totalTime = payload;
    },

    // setAiModelId
    setAiModelId(state, payload) {
      state.aiModelId = payload;
    },

    // setTimeLeft
    setTimeLeft(state, payload) {
      state.timeLeft = payload;
    },

    // setTime
    setTime(state, payload) {
      state.time = payload;
    },

    // setCountDown
    setCountDown(state, payload) {
      state.countDown = payload;
    },

    // resetState
    resetState(state) {
      state.addAiModelDialog = false;
      state.dialogAiModel = false;
      state.totalTime = 15 * 60 * 1000;
      state.aiModelId = 0;
      state.timeLeft = 0;
      state.time = 0;
      state.countDown = 0;
    },
  },

  actions: {},

  getters: {
    // getAddAiModelDialog
    getAddAiModelDialog(state) {
      return state.addAiModelDialog;
    },

    // getDialogAiModel
    getDialogAiModel(state) {
      return state.dialogAiModel;
    },

    // getTotalTime
    getTotalTime(state) {
      return state.totalTime;
    },

    // getAiModelId
    getAiModelId(state) {
      return state.aiModelId;
    },

    // getTimeLeft
    getTimeLeft(state) {
      return state.timeLeft;
    },

    // getTime
    getTime(state) {
      return state.time;
    },

    // getCountDown
    getCountDown(state) {
      return state.countDown;
    },
  },
};

export default aiModel;
