import router from "../../router";

const auth = {
  namespaced: true,

  state: {
    loggedIn: false,
    token: "",
    user: {},
    redirect: "",
  },

  mutations: {
    // setLoggedIn
    setLoggedIn(state, payload) {
      state.loggedIn = payload;
    },

    // setToken
    setToken(state, payload) {
      state.token = payload;
    },

    // setUser
    setUser(state, payload) {
      state.user = payload;
    },

    // setRedirect
    setRedirect(state, payload) {
      state.redirect = payload;
    },

    // resetState
    resetState(state) {
      // console.log("Log Out.");

      state.loggedIn = false;
      state.token = "";
      state.user = {};

      localStorage.setItem("token", "");
    },
  },

  actions: {
    // logOutUser
    logOutUser({ commit, rootState }) {
      // setRedirect
      commit("setRedirect", window.location.pathname);

      // resetState
      commit("resetState");

      // redirect
      router.push({
        name: "Login",
        query: { redirect: rootState.auth.redirect },
      });
    },
  },

  getters: {
    // getLoggedIn
    getLoggedIn(state) {
      return state.loggedIn;
    },

    // getToken
    getToken(state) {
      return state.token;
    },

    // getUser
    getUser(state) {
      return state.user;
    },

    // getShowVerifyEmail
    getShowVerifyEmail(state) {
      // email_verified_at
      if("email_verified_at" in state.user && state.user.email_verified_at === null){
        return true;
      }

      return false;
    },
  },
};

export default auth;
