const companies = {
  namespaced: true,

  state: {
    companies: [],
  },

  mutations: {
    // setCompanies
    setCompanies(state, payload) {
      state.companies = payload;
    },

    // resetState
    resetState(state) {
      state.companies = [];
    },
  },

  actions: {},

  getters: {
    // getCompanies
    getCompanies(state) {
      return state.companies;
    },
  },
};

export default companies;
