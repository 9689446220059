const roi = {
  namespaced: true,

  state: {
    dialogRoi: false,
    imgSrc: "",
    imgId: 0,
    image: {},
    roi: {},
    roiId: 0,
    allowAddingROI: false,
    imageWidth: 0,
    imageHeight: 0,
  },

  mutations: {
    // setDialogRoi
    setDialogRoi(state, payload) {
      state.dialogRoi = payload;
    },

    // setImgSrc
    setImgSrc(state, payload) {
      state.imgSrc = payload;
    },

    // setImgId
    setImgId(state, payload) {
      state.imgId = payload;
    },

    // setImage
    setImage(state, payload) {
      state.image = payload;
    },

    // setROI
    setROI(state, payload) {
      state.roi = payload;
    },

    // setROIId
    setROIId(state, payload) {
      state.roiId = payload;
    },

    // setAllowAddingROI
    setAllowAddingROI(state, payload) {
      state.allowAddingROI = payload;
    },

    // setImageWidth
    setImageWidth(state, payload) {
      state.imageWidth = payload;
    },

    // setImageHeight
    setImageHeight(state, payload) {
      state.imageHeight = payload;
    },

    // resetState
    resetState(state) {
      state.dialogRoi = false;
      state.imgSrc = "";
      state.imgId = 0;
      state.image = {};
      state.roi = {};
      state.roiId = 0;
      state.allowAddingROI = false;
      state.imageWidth = 0;
      state.imageHeight = 0;
    },
  },

  actions: {},

  getters: {
    // getDialogRoi
    getDialogRoi(state) {
      return state.dialogRoi;
    },

    // getImgSrc
    getImgSrc(state) {
      return state.imgSrc;
    },

    // getImgId
    getImgId(state) {
      return state.imgId;
    },

    // getImage
    getImage(state) {
      return state.image;
    },

    // getROI
    getROI(state) {
      return state.roi;
    },

    // getROIId
    getROIId(state) {
      return state.roiId;
    },

    // getAllowAddingROI
    getAllowAddingROI(state) {
      return state.allowAddingROI;
    },

    // getImageWidth
    getImageWidth(state) {
      return state.imageWidth;
    },

    // getImageHeight
    getImageHeight(state) {
      return state.imageHeight;
    },
  },
};

export default roi;
