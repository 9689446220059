import axios from "axios";

const baseDomain = process.env.VUE_APP_API_BASE_URL;

const baseURL = `${baseDomain}`;

const instance = axios.create({
  baseURL,
});

instance.defaults.headers = {
  AppApiKey: process.env.VUE_APP_API_KEY,
};

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
