import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3391ff",
        secondary: "#060a23",
        tertiary: "#d3e1ff",
        accent: "#243235",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        red1: "#F6655A",
        gray50: "#F8F8F8",
        gray100: "#EEEEEE",
        gray200: "#ECEDF0",
        gray300: "#DDDDDD",
        gray700: "#999999",
        gray800: "#3D3D3D",
        background: "#F8F8F8",
        primary2: "#060A23",
        transparentCard: "#12172E",
      },
    },
  },
  icons: {
    iconfont: "md",
  },
});
